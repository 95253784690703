import { Box, Grid, Link } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import Typography from '~/src/shared/components/display/Typography'
import Button from '~/src/shared/components/inputs/Button'
import MainContentContainer from '~/src/shared/components/layout/MainContentContainer'
import browserConfig from '~/src/shared/config/browser'
import { SSRPage, withServerSideProps } from '~/src/shared/helpers/withServerSidePropsDecorator'

const TOUCHBISTRO_LOGO = browserConfig.buildAssetsPath(
  '/assets/images/TouchBistro_R_Primary_RGB.png'
)
const GE_NAV_BUTTON =
  'https://d2o5lhoq0b4p6q.cloudfront.net/immutable/images/guest-engagement/guest-engagement-button.5f188c039d71e06ec86678803aa81449eff202e6.png'

export const getServerSideProps = withServerSideProps({ shouldAuthenticate: false })

const useStyles = makeStyles()((theme) => ({
  bg: {
    background: theme.palette.tb.white,
  },
  boldField: {
    fontFamily: theme.typography.fontFamilyMedium,
  },
  helpfulBox: {
    padding: '28px',
    backgroundColor: theme.palette.tb.cloud,
    borderRadius: theme.shape.borderRadius,
  },
  image: {
    width: '100%',
  },
  hideUnderSm: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

const TableupLegacyRedirectPage: SSRPage = () => {
  const { t } = useTranslation()
  const { classes, cx } = useStyles()

  return (
    <Box className={classes.bg}>
      <MainContentContainer domain="guest-engagement">
        <Grid container gap="52px">
          <Grid item mobile={12} sm={7} gap="52px" display="flex" flexDirection="column">
            <Box maxWidth={300}>
              <img src={TOUCHBISTRO_LOGO} alt="TouchBistro logo" className={classes.image} />
            </Box>
            <Box>
              <Typography variant="h1" noMargin>
                {t('ge.tu_redirect_page.title')}
              </Typography>
              <Box mt="28px">
                <Typography variant="body" noMargin>
                  <Trans
                    i18nKey="ge.tu_redirect_page.subtitle"
                    components={{
                      1: <strong className={classes.boldField} />,
                    }}
                  />
                </Typography>
              </Box>
            </Box>
            <Box width="fit-content">
              <Button
                data-pw="submit-delete-device"
                label={t('ge.tu_redirect_page.login_btn')}
                href="/"
                variant="contained"
              />
            </Box>
            <Typography variant="body" noMargin>
              {t('ge.tu_redirect_page.desc')}
            </Typography>
            <Box gap="28px" display="flex" flexDirection="column">
              <Typography variant="body" noMargin>
                <Trans
                  i18nKey="ge.tu_redirect_page.phone"
                  components={{
                    1: <strong className={classes.boldField} />,
                    2: <Link href="tel:+1-888-342-0131" underline="hover" />,
                  }}
                />
              </Typography>
              <Typography variant="body" noMargin>
                <Trans
                  i18nKey="ge.tu_redirect_page.email"
                  components={{
                    1: <strong className={classes.boldField} />,
                    2: <Link href="mailto:valueaddedservices@touchbistro.com" underline="hover" />,
                  }}
                />
              </Typography>
            </Box>
            <Box className={classes.helpfulBox}>
              <Typography variant="overline" textTransform="uppercase" noMargin>
                {t('ge.tu_redirect_page.helpful')}
              </Typography>
              <Box gap="28px" mt="1rem" display="flex" flexDirection="column">
                <Typography variant="bodyBold" noMargin>
                  <Link
                    href="https://help.touchbistro.com/s/article/Getting-Started-with-Guest-Engagement?language=en_US"
                    underline="hover"
                  >
                    {t('ge.tu_redirect_page.helpful_links.1')}
                  </Link>
                </Typography>
                <Typography variant="bodyBold" noMargin>
                  <Link
                    href="https://help.touchbistro.com/s/article/user-management?language=en_US"
                    underline="hover"
                  >
                    {t('ge.tu_redirect_page.helpful_links.2')}
                  </Link>
                </Typography>
                <Typography variant="bodyBold" noMargin>
                  <Link
                    href="https://help.touchbistro.com/s/article/changing-your-password-in-cloud?language=en_US"
                    underline="hover"
                  >
                    {t('ge.tu_redirect_page.helpful_links.3')}
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item mobile={0} sm={4}>
            <img
              className={cx(classes.image, classes.hideUnderSm)}
              src={GE_NAV_BUTTON}
              alt="Guest Engagement Navigation Button zoomed in"
            />
          </Grid>
        </Grid>
      </MainContentContainer>
    </Box>
  )
}

export default TableupLegacyRedirectPage
