import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import Typography from '~/src/shared/components/display/Typography'
import Banner from '~/src/shared/components/feedback/Banner'
import { useGlobalContext } from '~/src/shared/context/GlobalContext'
import { type BaseProps } from '~/src/shared/helpers/withServerSidePropsDecorator'
import { BaseType, Domain } from '~/src/shared/types'

export const domainNameTKey = (domain: string): string => {
  switch (domain) {
    case 'guest-engagement':
      return 'ge.guest_engagement'
    case 'agent':
      return 'agent_text'
    case 'marketplace':
      return 'app_marketplace'
    case 'devices':
      return 'devices.devices'
    case 'ordering':
      return 'online_ordering'
    case 'menus':
      return 'menu'
    case 'menu-management':
      return 'menu_management'
    case 'venue-info':
      return 'acc.venue_info'
    case 'reports':
      return 'report.reports'
    case 'settings':
      return 'settings'
    case 'floorplan':
      return 'floorplan'
    case 'payments':
      return 'payments'
    case 'staff':
      return 'staff'
    case 'pos-linking':
      return 'pos-linking'
    case 'signup':
      return 'signup'
    default:
      return domain
  }
}

interface BaseSwitchBannerProps {
  domain: Domain
  onDismiss: () => void
}

const useStyles = makeStyles()((theme) => ({
  fontFamilyMedium: {
    fontFamily: theme.typography.fontFamilyMedium,
  },
}))

const BaseSwitchBanner: FC<BaseSwitchBannerProps> = ({ domain, onDismiss }) => {
  const { base } = useGlobalContext()
  const { classes } = useStyles()
  const { t } = useTranslation()

  const getTContext = (baseProps: BaseProps | null): string | undefined => {
    if (!baseProps) {
      return undefined
    }
    switch (baseProps.type) {
      case BaseType.VENUE:
        return 'venue'
      case BaseType.ROOT:
        return 'corporation'
      case BaseType.GROUP:
        return 'group'
      default:
        return undefined
    }
  }

  return (
    <Banner
      type="warning"
      onDismiss={onDismiss}
      content={
        <Typography variant="body">
          <Trans
            i18nKey="base_warning_banner"
            values={{ domain: t(domainNameTKey(domain)), baseName: base?.name }}
            components={{
              1: <strong className={classes.fontFamilyMedium} />,
              2: <strong className={classes.fontFamilyMedium} />,
            }}
            context={getTContext(base)}
          />
        </Typography>
      }
    />
  )
}

export default BaseSwitchBanner
