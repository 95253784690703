import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import Typography from '~/src/shared/components/display/Typography'
import Banner from '~/src/shared/components/feedback/Banner'
import { domainNameTKey } from '~/src/shared/components/feedback/BaseSwitchBanner'
import { Domain } from '~/src/shared/types'

interface MultiVenueBannerProps {
  domain: Domain
  onDismiss: () => void
}

const useStyles = makeStyles()((theme) => ({
  fontFamilyMedium: {
    fontFamily: theme.typography.fontFamilyMedium,
  },
}))

const MultiVenueBanner: FC<MultiVenueBannerProps> = ({ domain, onDismiss }) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  return (
    <Banner
      type="warning"
      onDismiss={onDismiss}
      content={
        <Typography variant="body">
          <Trans
            i18nKey="base_warning_banner_multi_venue"
            values={{ domain: t(domainNameTKey(domain)) }}
            components={{
              1: <strong className={classes.fontFamilyMedium} />,
              2: <strong className={classes.fontFamilyMedium} />,
            }}
          />
        </Typography>
      }
    />
  )
}

export default MultiVenueBanner
