import { FC, ReactNode, useState } from 'react'
// eslint-disable-next-line no-restricted-imports
import { Breakpoint, Container } from '@mui/material'
import { useRouter } from 'next/router'
import { makeStyles } from 'tss-react/mui'
import BaseSwitchBanner from '~/src/shared/components/feedback/BaseSwitchBanner'
import MultiVenueBanner from '~/src/shared/components/feedback/MultiVenueBanner'
import { useLayout } from '~/src/shared/components/templates/LayoutContext'
import { Domain } from '~/src/shared/types'

interface IMainContentContainerProps {
  domain: Domain
  maxWidth?: Extract<Breakpoint, 'sm' | 'md' | 'lg'>
  centered?: boolean
  gap?: string
  children?: ReactNode
}

const useStyles = makeStyles<{ gap?: IMainContentContainerProps['gap']; appBarHeight?: number }>()(
  (theme, { gap, appBarHeight }) => ({
    overflowContainer: {
      overflowY: 'auto',
      height: `calc(100% - ${appBarHeight}px)`,
    },
    main: {
      ...theme.mixins.contentPadding,
      margin: '0',
    },
    maxWidthSm: {
      [theme.breakpoints.up('sm')]: {
        maxWidth: `${theme.breakpoints.values.sm}px`,
      },
    },
    maxWidthMd: {
      [theme.breakpoints.up('md')]: {
        maxWidth: `${theme.breakpoints.values.md}px`,
      },
    },
    maxWidthLg: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: `${theme.breakpoints.values.lg}px`,
      },
    },
    centered: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    gap: {
      gap,
      display: 'flex',
      flexDirection: 'column',
    },
  })
)

const MainContentContainer: FC<IMainContentContainerProps> = ({
  children,
  maxWidth = 'lg',
  centered = true,
  gap,
  domain,
}) => {
  const { appBar } = useLayout()
  const router = useRouter()
  const { classes, cx } = useStyles({
    gap,
    appBarHeight: appBar.height,
  })
  const [shouldDisplayBaseSwitchBanner, setShouldDisplayBaseSwitchBanner] = useState(
    !!router.query['base-changed']
  )
  const [shouldDisplayMultiVenueBanner, setShouldDisplayMultiVenueBanner] = useState(
    !!router.query['multi-venue']
  )

  return (
    <div className={classes.overflowContainer}>
      <Container
        disableGutters
        className={cx({
          [classes.main]: true,
          [classes.maxWidthSm]: maxWidth === 'sm',
          [classes.maxWidthMd]: maxWidth === 'md',
          [classes.maxWidthLg]: maxWidth === 'lg',
          [classes.centered]: centered,
          [classes.gap]: gap !== undefined,
        })}
      >
        {shouldDisplayBaseSwitchBanner && (
          <BaseSwitchBanner
            onDismiss={() => setShouldDisplayBaseSwitchBanner(false)}
            domain={domain}
          />
        )}
        {shouldDisplayMultiVenueBanner && (
          <MultiVenueBanner
            onDismiss={() => setShouldDisplayMultiVenueBanner(false)}
            domain={domain}
          />
        )}
        {children}
      </Container>
    </div>
  )
}

export default MainContentContainer
