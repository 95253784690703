import { createContext, FunctionComponent, useContext, useEffect, useReducer } from 'react'
// eslint-disable-next-line no-restricted-imports
import { Container, IconButton, SvgIcon, Theme, useMediaQuery } from '@mui/material'
import storageAvailable from 'storage-available'
import { makeStyles } from 'tss-react/mui'
import { ChevronLeft2pt, ChevronRight2pt } from '~/src/shared/components/data_display/Icons'
import theme from '~/src/shared/styles/theme'

interface AppBarPayload {
  height: number
}

interface LayoutSidebarPayload {
  isSidebarOpen: boolean
  isHovering: boolean
  isPersistent: boolean
}

enum LayoutActionType {
  SET_SIDEBAR_STATUS,
  TOGGLE_PERSISTENT_SIDEBAR,
  OPEN_PERSISTENT_SIDEBAR,
  CLOSE_PERSISTENT_SIDEBAR,
  OPEN_HOVER_SIDEBAR,
  CLOSE_HOVER_SIDEBAR,
  UPDATE_APP_BAR_HEIGHT,
}

type LayoutActions =
  | {
      type: LayoutActionType.TOGGLE_PERSISTENT_SIDEBAR
    }
  | {
      type: LayoutActionType.OPEN_PERSISTENT_SIDEBAR
    }
  | {
      type: LayoutActionType.CLOSE_PERSISTENT_SIDEBAR
    }
  | {
      type: LayoutActionType.OPEN_HOVER_SIDEBAR
    }
  | {
      type: LayoutActionType.CLOSE_HOVER_SIDEBAR
    }
  | {
      type: LayoutActionType.UPDATE_APP_BAR_HEIGHT
      payload: AppBarPayload
    }

interface State {
  appBar: AppBarPayload
  sidebar: LayoutSidebarPayload
}

const initialState: State = {
  appBar: { height: 0 },
  sidebar: {
    isSidebarOpen: true,
    isHovering: false,
    isPersistent: true,
  },
}

// We need to be defensive to make sure that localStorage is available before using it.
// We need a dependency, and can't just see if window.localStorage is defined, because of browser gotcha like Private Mode in safari,
// https://gist.github.com/paulirish/5558557
const localStorageAvailable = storageAvailable('localStorage')

const getInitialState = (): State => {
  // Local
  const sidebarState = localStorageAvailable ? localStorage.getItem('sidebarState') : undefined
  return sidebarState ? { ...initialState, sidebar: JSON.parse(sidebarState) } : initialState
}

const layoutReducer = (state: State, action: LayoutActions): State => {
  switch (action.type) {
    case LayoutActionType.TOGGLE_PERSISTENT_SIDEBAR:
      return {
        ...state,
        sidebar: {
          isSidebarOpen: !state.sidebar.isSidebarOpen,
          isHovering: false,
          isPersistent: !state.sidebar.isSidebarOpen,
        },
      }
    case LayoutActionType.OPEN_PERSISTENT_SIDEBAR:
      return {
        ...state,
        sidebar: {
          isSidebarOpen: true,
          isHovering: false,
          isPersistent: true,
        },
      }
    case LayoutActionType.CLOSE_PERSISTENT_SIDEBAR:
      return {
        ...state,
        sidebar: {
          isSidebarOpen: false,
          isHovering: false,
          isPersistent: false,
        },
      }
    case LayoutActionType.OPEN_HOVER_SIDEBAR:
      return {
        ...state,
        sidebar: {
          isSidebarOpen: true,
          isHovering: true,
          isPersistent: false,
        },
      }
    case LayoutActionType.CLOSE_HOVER_SIDEBAR:
      return {
        ...state,
        sidebar: {
          isSidebarOpen: false,
          isHovering: false,
          isPersistent: state.sidebar.isPersistent,
        },
      }
    case LayoutActionType.UPDATE_APP_BAR_HEIGHT:
      return {
        ...state,
        appBar: {
          height: action.payload.height,
        },
      }
    default:
      throw new Error('Unexpected action')
  }
}

const LayoutContext = createContext({
  state: initialState,
  dispatch: (value: LayoutActions) => {}, // eslint-disable-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
})

const useStyles = makeStyles()((theme) => ({
  mainContainer: {
    width: '100vw',
    backgroundColor: theme.palette.tb.slate,
  },
  main: {
    position: 'relative',
    backgroundColor: theme.palette.tb.white,
    minWidth: `${theme.breakpoints.values.mobile}px`,
    maxWidth: '100%',

    [theme.breakpoints.up('lg')]: {
      margin: '0 auto',
      // TODO: @pprabagaran re-enable when we decide https://touchbistro.slack.com/archives/C046X5WGB3N/p1667242669823909
      // maxWidth: `${theme.breakpoints.values.lg}px`,
    },
  },
  sidebarToggleContainer: {
    position: 'absolute',
    zIndex: 1201, // sidebar z-index + 1
    top: `calc(${theme.topBar.height}px - 18px)`,
    left: `calc(${theme.drawer.mediumAndUp.width.closed}px - 18px)`,
  },
  sidebarOpen: {
    left: `calc(${theme.drawer.mediumAndUp.width.open}px - 18px)`,
  },
  toggleSidebarBtn: {
    background: theme.palette.tb.white,
    padding: 10,
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.2)',
    filter: 'drop-shadow(0 0 4px rgba(0, 0, 0, 0.2))',
    borderRadius: '100%',

    '&:hover, &:focus': {
      background: theme.palette.tb.cloud,
    },

    '& svg': {
      fill: theme.palette.tb.navy,
      width: 16,
      height: 16,
    },
  },
}))

interface ILayoutProviderProps {
  hideNavigation?: boolean
  children?: React.ReactNode
}

const LayoutProvider: FunctionComponent<ILayoutProviderProps> = ({ children, hideNavigation }) => {
  const [state, dispatch] = useReducer(layoutReducer, getInitialState())
  const { classes, cx } = useStyles()
  const isMediumAndUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  useEffect(() => {
    if (localStorageAvailable) {
      localStorage.setItem('sidebarState', JSON.stringify(state.sidebar))
    }
  }, [state.sidebar])

  const toggleSidebar = (): void => {
    dispatch({ type: LayoutActionType.TOGGLE_PERSISTENT_SIDEBAR })
  }

  return (
    <LayoutContext.Provider value={{ state, dispatch }}>
      <div className={classes.mainContainer}>
        <Container disableGutters className={classes.main}>
          {!hideNavigation && isMediumAndUp && !state.sidebar.isHovering && (
            <div
              className={cx(
                classes.sidebarToggleContainer,
                state.sidebar.isSidebarOpen && classes.sidebarOpen
              )}
            >
              <IconButton
                data-pw="toggle-drawer"
                classes={{ root: classes.toggleSidebarBtn }}
                onClick={toggleSidebar}
                tabIndex={0}
              >
                <SvgIcon htmlColor={theme.palette.tb.navy}>
                  {state.sidebar.isSidebarOpen ? ChevronLeft2pt : ChevronRight2pt}
                </SvgIcon>
              </IconButton>
            </div>
          )}
          {children}
        </Container>
      </div>
    </LayoutContext.Provider>
  )
}

// Convenience hook
const useLayout = (): State & {
  toggleSidebar: typeof toggleSidebar
  openPersistentSidebar: typeof openPersistentSidebar
  closePersistentSidebar: typeof closePersistentSidebar
  openHoverSidebar: typeof openHoverSidebar
  closeHoverSidebar: typeof closePersistentSidebar
  updateAppBarHeight: typeof updateAppBarHeight
} => {
  const { state, dispatch } = useContext(LayoutContext)

  const toggleSidebar = (): void => {
    dispatch({ type: LayoutActionType.TOGGLE_PERSISTENT_SIDEBAR })
  }

  const openPersistentSidebar = (): void => {
    dispatch({ type: LayoutActionType.OPEN_PERSISTENT_SIDEBAR })
  }

  const closePersistentSidebar = (): void => {
    dispatch({ type: LayoutActionType.CLOSE_PERSISTENT_SIDEBAR })
  }

  const openHoverSidebar = (): void => {
    dispatch({ type: LayoutActionType.OPEN_HOVER_SIDEBAR })
  }

  const closeHoverSidebar = (): void => {
    dispatch({ type: LayoutActionType.CLOSE_HOVER_SIDEBAR })
  }

  const updateAppBarHeight = (height: number): void => {
    dispatch({ type: LayoutActionType.UPDATE_APP_BAR_HEIGHT, payload: { height } })
  }

  return {
    ...state,
    toggleSidebar,
    openPersistentSidebar,
    closePersistentSidebar,
    openHoverSidebar,
    closeHoverSidebar,
    updateAppBarHeight,
  }
}

export { LayoutContext, LayoutProvider, useLayout }
