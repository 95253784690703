import React, { FunctionComponent, useMemo } from 'react'
import { Box, SvgIcon } from '@mui/material'

import { makeStyles } from 'tss-react/mui'
import {
  CloseIcon,
  AlertIcon,
  Info2pt,
  IconActive1pt,
} from '~/src/shared/components/data_display/Icons'
import Typography from '~/src/shared/components/display/Typography'

export interface BannerProps {
  type?: 'error' | 'warning' | 'info' | 'success'
  onDismiss?: () => void
  message?: string
  content?: React.ReactElement
  id?: string
  displayIcon?: boolean
}

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: '16px 28px',
    border: `1px solid`,
    boxSizing: 'border-box',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  mainIcon: {
    maxWidth: 24,
    maxHeight: 24,
    marginRight: 24,
    marginTop: 8,
  },
  closeIcon: {
    alignSelf: 'start',
    marginLeft: 24,
    cursor: 'pointer',
    marginTop: 8,
    width: 24,
    height: 24,
    padding: 4,
  },
  infoIcon: {
    fill: theme.palette.tb.navy,
  },
  redBorder: {
    borderColor: theme.palette.tb.scarlet,
    background: theme.palette.tb.shell,
  },
  yellowBorder: {
    borderColor: theme.palette.tb.pumpkin,
    background: theme.palette.tb.lightYellow,
  },
  blueBorder: {
    borderColor: theme.palette.tb.navy,
    background: theme.palette.tb.cloud,
  },
  greenBorder: {
    borderColor: theme.palette.tb.kale,
    background: theme.palette.tb.mintCream,
  },
}))

const Banner: FunctionComponent<BannerProps> = ({
  message,
  content,
  id,
  type = 'error',
  displayIcon = true,
  onDismiss,
}) => {
  const { classes, cx } = useStyles()
  const { className, icon } = useMemo((): {
    className: string
    icon: JSX.Element
  } => {
    switch (type) {
      case 'error': {
        return {
          className: classes.redBorder,
          icon: <AlertIcon className={classes.mainIcon} filling="scarlet" size="24px" />,
        }
      }
      case 'warning': {
        return {
          className: classes.yellowBorder,
          icon: <AlertIcon className={classes.mainIcon} filling="pumpkin" size="24px" />,
        }
      }
      case 'info': {
        return {
          className: classes.blueBorder,
          icon: <SvgIcon className={cx(classes.mainIcon, classes.infoIcon)}>{Info2pt}</SvgIcon>,
        }
      }
      case 'success': {
        return {
          className: classes.greenBorder,
          icon: <IconActive1pt className={classes.mainIcon} size="24px" />,
        }
      }
      default: {
        return {
          className: classes.redBorder,
          icon: <AlertIcon className={classes.mainIcon} filling="scarlet" size="24px" />,
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  return (
    <Box
      id={id ?? 'banner'}
      data-pw={id ?? 'banner'}
      className={cx(classes.container, className)}
      justifyContent="space-between"
      alignItems="start"
    >
      <Box display="flex" alignItems="start">
        {displayIcon && icon}
        {message ? <Typography variant="body">{message}</Typography> : <Box>{content}</Box>}
      </Box>
      {onDismiss != null && (
        <Box className={classes.closeIcon}>
          <CloseIcon
            data-pw="warning-message-close-icon"
            onClick={onDismiss}
            filling="heatherGrey"
            size="16px"
          />
        </Box>
      )}
    </Box>
  )
}

export default Banner
